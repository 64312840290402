import React, { useEffect  } from 'react';
import './Shop.css';

function Shop() {

      useEffect(()=>{
            //loadShopItems();
      })
 
      function myComponent() {
            //return <div dangerouslySetInnerHTML={{__html: loadShopItems()}} />;
      }

      return (
            <div className="Shop">
                  <p>Kauppa!</p>
                  {myComponent()}
            </div>
      );
}

export default Shop;
