import React, { useState, useEffect } from 'react';
import './Fabric.css';
import { loadGalleryItems } from './GraphApi';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

import {
      Link,
} from "react-router-dom";

function Fabric(props) {

      useEffect(() => {
            createGallery()
      }, []);

      const [galleryContent, setGalleryContent] = useState();

      function createGalleryItem(item) {
            return (
                  <Link key={item.id} to={{ search: item.id }}>
                        <div className="gallery-item" key={item.id} onClick={() => getItem(item.id)}>
                              <div className="hover-color"></div>
                              <img style={{ width: "100%", display: "block" }} alt="noimage" src={item.icon} alt="error" />
                              <p>{item.title}</p>
                        </div>
                  </Link>
            )
      }

      function createGallery() {
            loadGalleryItems('fabric')
                  .then(galleryItems => {
                        galleryItems.data.listGallery.sort(function (b, a) {
                              return a.priority.localeCompare(b.priority, undefined, { 'numeric': true });
                        });

                        const temp = [];
                        galleryItems.data.listGallery.forEach(element => {
                              temp.push(createGalleryItem(element));
                        });
                        setGalleryContent(temp)
                  })
      }

      function getItem(id) {
            props.useThat(id)
      }

      return (
            <div className="Gallery">
                  {props.item != null &&
                        <div className="gallery-item-content" dangerouslySetInnerHTML={{ __html: props.item }} />
                  }
                  <div className="gallery-list">
                        {props.item == null &&
                        <div className="fabric-text">
                              <h3>titiit</h3>
                              <p>taatataaa laaal lalaa Minni on söpö kun se laulaaaaaaaa</p>
                        </div>
                        }
                        <ResponsiveMasonry
                              columnsCountBreakPoints={{ 350: 1, 1200: 2, 900: 4 }}
                        >
                              <Masonry gutter="10px">
                                    {galleryContent}
                              </Masonry>
                        </ResponsiveMasonry>
                  </div>
            </div>
      );
}

export default Fabric;
