import React, { useEffect  } from 'react';
import './About.css';

function About() {

    useEffect(()=>{
        //loadShopItems();
    })

    return (
        <div className="About">
            <div className="about-images">
                <img alt="noimage" src="essi.png"></img>
                {/* <img alt="noimage" src="Sieppaa3.PNG"></img> */}
            </div>
            <div className="about-text">
                <h3>About me</h3>
                <p>Hi, my name is Essi!
                <br/>
                I'm a Finnish digital artist living in Uusimaa area, Finland. I have a degree in Graphic Design and did my BA in Game Design. I create illustrations as a freelance artist, and in my free time I design all kinds of print products for my shop.  I'm currently interested in illustration-heavy projects but I'm open to many job opportunities.
                <br/>
                <br/>
                My art style lies somewhere between illustration and contemporary art or pop art. However I'm able to illustrate in various styles depending of the project. To this portfolio I've collected mainly my illustration works and projects that represent my style the best.
                <br/>
                <br/>
                In case of inquiries or questions, contact me at: essi.t.design(at)gmail.com
                <br/>
                <br/>
                Essi Tommila
                <br/>
                essi.t.design(at)gmail.com
                <br/>
                <br/>
                <a href="https://www.instagram.com/essikissa/?hl=fi">Instagram</a>
                <br/>
                {/* <a href="http://shop.essitommila.net">Shop</a> */}
                </p>
            </div>
            
        </div>
    );
}

export default About;