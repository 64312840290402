import React, { useState, useEffect, useRef } from 'react';
import './Gallery.css';
import { loadGalleryItems } from './GraphApi';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

import {
      Link,
} from "react-router-dom";

function Gallery(props) {

      const galleryCanvasRef = useRef(null);

      useEffect(() => {
            createGallery()
      }, []);

      // useEffect will run on galleryCanvasRef value assignment
      useEffect(() => {

            // The 'current' property contains info of the reference:
            // align, title, ... , width, height, etc.
            if (galleryCanvasRef.current) {
                  setGalleryWidth(galleryCanvasRef.current.offsetWidth);
            }

      }, [galleryCanvasRef]);

      const [galleryContent, setGalleryContent] = useState([]);
      const [galleryWidth, setGalleryWidth] = useState(350);

      function createGalleryItem(item) {
            return (
                  <Link key={item.id} to={{ search: item.id }}>
                        <div className="gallery-item" key={item.id} onClick={() => getItem(item.id)}>
                              <div className="hover-color"></div>
                              <img style={{ width: "100%", display: "block" }} alt="noimage" src={item.icon} alt="error" />
                              <p>{item.title}</p>
                        </div>
                  </Link>
            )
      }

      function createGallery() {
            loadGalleryItems('gallery')
                  .then(galleryItems => {
                        galleryItems.data.listGallery.sort(function (b, a) {
                              return a.priority.localeCompare(b.priority, undefined, { 'numeric': true });
                        });

                        const temp = [];
                        galleryItems.data.listGallery.forEach(element => {
                              temp.push(createGalleryItem(element));
                        });
                        setGalleryContent(temp)
                  })
      }

      function getItem(id) {
            props.useThat(id)
      }

      return (
            <div className="Gallery" ref={galleryCanvasRef}>
                  {/* <h1>props.item</h1> */}
                  {props.item != null &&
                        <div className="gallery-item-content" dangerouslySetInnerHTML={{ __html: props.item }} />
                  }
                  <div className="gallery-list">
                        <ResponsiveMasonry
                              columnsCountBreakPoints={{ 350: 3, 750: 3, 900: 5 }}
                        >
                              <Masonry gutter={galleryWidth>=1200 ? "17px" : "5px"}>
                                    {galleryContent}
                              </Masonry>
                        </ResponsiveMasonry>
                  </div>
            </div>
      );
}

export default Gallery;
