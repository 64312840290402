import React, { useState, useEffect } from "react";
import "./App.css";
import Shop from "./Shop";
import Gallery from "./Gallery";
import Fabric from "./Fabric";
import About from "./About";
import { loadItems, loadSite } from "./GraphApi";
import Helmet from "react-helmet";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  Redirect,
  useLocation,
} from "react-router-dom";

function App() {

  const location = useLocation();

  const [galleryItemContent, setGalleryItemContent] = useState();
  const [pageContent, setPageContent] = useState();

  const [displayNavbar, setDisplayNavbar] = useState(true);

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  let history = useHistory();

  useEffect(() => {
    setDisplayNavbar(window.innerWidth <= 1200 ? false : true);

    setGalleryItemContent();
    setPageContent();
    if (window.location.pathname) {
      const s = window.location.pathname.substr(
        1,
        window.location.pathname.length
      );

      loadSite(s).then((content) => {
        if (content.data.viewSite == null) return;
        setPageContent(decodeURI(content.data.viewSite.content));
      });
      return;
    }
  }, [window.location.pathname]);

  useEffect(() => {}, [history.location.pathname]);

  useEffect(() => {
    setGalleryItemContent();
    setPageContent();
  }, [window.location.search]);

  function useThat(id) {
    if (!id) {
      setGalleryItemContent();
      setPageContent();
      return;
    }
    loadItems(id).then((content) => {
      window.scrollTo(0, 0);
      setGalleryItemContent(decodeURI(content.data.viewGallery.content));
    });
  }

  function galleryClick() {
    setGalleryItemContent();
    setPageContent();
  }
  function aboutClick() {
    setGalleryItemContent();
    setPageContent();
  }

  function contactClick() {
    loadSite("contact").then((content) => {
      setPageContent(decodeURI(content.data.viewSite.content));
    });
  }
  function commissionsClick() {
    loadSite("Commissions & Custom Works").then((content) => {
      setPageContent(decodeURI(content.data.viewSite.content));
    });
  }

  function miscellaneousClick() {
    loadSite("miscellaneous").then((content) => {
      setPageContent(decodeURI(content.data.viewSite.content));
    });
  }

  function shopClick() {
    loadSite("Store").then((content) => {
      setPageContent(decodeURI(content.data.viewSite.content));
    });
  }

  return (
    <div>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        <title>Essi Tommila Illustration Portfolio</title>
        <meta name="description" content="Illustrator Essi Tommila. " />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Essi Tommila Illustration Portfolio" />
        <meta
          itemprop="description"
          content="Illustrator and Designer Essi Tommila. "
        />
        <meta
          itemprop="image"
          content="https://et-kuulento-images.s3.eu-central-1.amazonaws.com/lohik%25C3%25A4%25C3%25A4rmejapallo_pieni.png"
        />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://essitommila.art" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Essi Tommila Illustration Portfolio"
        />
        <meta property="og:description" content="Illustrator Essi Tommila. " />
        <meta
          property="og:image"
          content="https://et-kuulento-images.s3.eu-central-1.amazonaws.com/lohik%25C3%25A4%25C3%25A4rmejapallo_pieni.png"
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Essi Tommila Illustration Portfolio"
        />
        <meta name="twitter:description" content="Illustrator Essi Tommila. " />
        <meta
          name="twitter:image"
          content="https://et-kuulento-images.s3.eu-central-1.amazonaws.com/lohik%25C3%25A4%25C3%25A4rmejapallo_pieni.png"
        />

        {/* <!-- Meta Tags Generated via http://heymeta.com --> */}
      </Helmet>
      <div className="App">
        <div className="essi-logo">
          {/* <h1>KuulentoStudio 🌔</h1> */}
          <img alt="Essi Tommila" src="/bannersaga.png"></img>
        </div>

        {dimensions.width < 1200 && (
          <div
            onClick={() => setDisplayNavbar(!displayNavbar)}
            className="top-nav-button"
          >
            <img src="/hamburger-menu-icon.png"></img>
            <h2>menu</h2>
          </div>
        )}

        <Route>
          {(displayNavbar || dimensions.width >= 1200) && (
            <div className="top-nav">
              <Link
                id={location.pathname === "/Gallery" ? "active" : ""}
                onClick={() => galleryClick()}
                to="/Gallery"
              >
                Gallery
              </Link>
              <Link
                id={location.pathname === "/News" ? "active" : ""}
                onClick={() => aboutClick()}
                to="/News"
              >
                News
              </Link>
              <Link
                id={
                  location.pathname === "/Commissions & Custom Works"
                    ? "active"
                    : ""
                }
                onClick={() => commissionsClick()}
                to="/Commissions & Custom Works"
              >
                Commissions & Custom Works
              </Link>
              <Link
                id={location.pathname === "/contact" ? "active" : ""}
                onClick={() => contactClick()}
                to="/contact"
              >
                Contact
              </Link>
              <Link
                id={location.pathname === "/about" ? "active" : ""}
                onClick={() => aboutClick()}
                to="/about"
              >
                About
              </Link>
              {/* <Link onClick={() => shopClick()} to='/Store'>Store</Link> */}
              {/* <a
                href="http://shop.essitommila.net"
                style={{ color: "#262626" }}
              >
                Shop
              </a> */}
              {/* <Link onClick={() => miscellaneousClick()} to='/misc'>Misc.</Link> */}

              <Link
                id="IG"
                onClick={() =>
                  window.open("https://www.instagram.com/essikissa/?hl=fi")
                }
              >
                Instagram
              </Link>
              {/* <a id="essi-kauppa-logo" href="http://shop.essitommila.net">
                <img src="/kauppalogo_mustavalkoinen.png" />
              </a> */}

              {/* <div className="topsocial-row"></div> */}
            </div>
          )}
          {pageContent != null && (
            <div
              className="gallery-item-content"
              dangerouslySetInnerHTML={{ __html: pageContent }}
            />
          )}
        </Route>

        <Switch>
          <Redirect exact from="/" to="Gallery" />
          <Route exact path="/Instagram">
            <Shop />
          </Route>
          <Route path="/Gallery">
            <Gallery useThat={useThat} item={galleryItemContent} />
          </Route>
          <Route path="/FabricStories">
            <Fabric useThat={useThat} item={galleryItemContent} />
          </Route>
          <Route path="/About">
            <About />
          </Route>
          <Route path="/contact">{contactClick}</Route>
          <Route path="/Commissions & Custom Works">{commissionsClick}</Route>
        </Switch>
        <div className="social-row">
          <p>© 2021 Essi Tommila All Rights Reserved</p>
        </div>
      </div>
    </div>
  );
}

export default App;
