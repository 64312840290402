const request = require('request');

export const loadSite = (id) => {
      return new Promise(function(resolve, reject){
            request.post({
                  headers: {'content-type' : 'application/x-www-form-urlencoded'},
                  url:     'https://0ocmr89aca.execute-api.eu-central-1.amazonaws.com/dev/gallery',
                  body:    `query {viewSite(id: "${id}"){content}}`
            }, function(error, response, body){
                  if(error){
                        reject(error)
                  }
                  resolve(JSON.parse(body));
            });
      });
}

export const loadGalleryItems = (galley) => {
      return new Promise(function(resolve, reject){
            request.post({
                  headers: {'content-type' : 'application/x-www-form-urlencoded'},
                  url:     'https://0ocmr89aca.execute-api.eu-central-1.amazonaws.com/dev/gallery',
                  body:    `query {listGallery(gallery: "${galley}"){id,title,icon,priority,gallery}}`
            }, function(error, response, body){
                  if(error){
                        reject(error)
                  }
                  resolve(JSON.parse(body));
            });
      });
}

export const loadItems = (id) => {
      return new Promise(function(resolve, reject){
            request.post({
                  headers: {'content-type' : 'application/x-www-form-urlencoded'},
                  url:     'https://0ocmr89aca.execute-api.eu-central-1.amazonaws.com/dev/gallery',
                  body:    `query {viewGallery(id: "${id}"){content}}`
            }, function(error, response, body){
                  if(error){
                        reject(error)
                  }
                  resolve(JSON.parse(body));
            });
      });
}